// import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { iconAdd, iconArrowRight } from 'assets';
import { useAuthStore } from 'store';
import { ELanguages } from 'lib/types';
import { ROUTES } from 'lib/constants';
import { useCurrentUserQuery } from 'api';
import { numberToMoney } from 'lib/utils';
// import { useDialogsAdd } from 'lib/contexts';

// DialogPayment
import { Box, Icon, Spinner, Text, Title } from 'components';

import st from './Balance.module.less';

interface IProps {
  className?: string;
}

export const Balance = ({ className }: IProps) => {
  const isAuth = useAuthStore.use.isAuth();

  const { t, i18n: { resolvedLanguage } } = useTranslation();
  // const addDialog = useDialogsAdd();

  const { isFetching, data: { balance = 0 } = {} } = useCurrentUserQuery({
    enabled: isAuth,
  });

  // const handleClick = useCallback(() => addDialog(<DialogPayment />), [addDialog]);

  return (
    <Box className={cn(st.balance, className)}>
      {isFetching && <Spinner absolute withBackdropBlur />}

      <Text className={st.title} color="grayBlue" weight="w500">
        {t('game.balance')}
      </Text>

      <p className={st.balanceInfo}>
        <Title as="span" className={st.value} size="h2">
          {isAuth ? numberToMoney(balance.toFixed(2)) : '∞'}
        </Title>

        {isAuth && (
          <Text uppercase rubik as="span" color="grayBlue" size="s" weight="w500">
            $1 = 1
            &nbsp;
            {t('chip')}
          </Text>
        )}
      </p>

      <Text
        as="div"
        className={cn(st.actions, { [st.russian]: resolvedLanguage === ELanguages.RU })}
        size="s"
        weight="w700"
      >
        {/* <button className={cn(st.btn, st.link)} type="button" onClick={handleClick}> */}
        <Link className={st.link} to={ROUTES.CASHIER}>
          {t('game.add_btn')}
          <Icon icon={iconAdd} size={16} />
        </Link>
        {/* </button> */}

        <Link className={st.link} to={ROUTES.PERSONAL_ACCOUNT}>
          {t('game.profile_btn')}
          <Icon icon={iconArrowRight} size={16} />
        </Link>
      </Text>
    </Box>
  );
};
